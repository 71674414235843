import { Link, graphql, useStaticQuery } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"

const Header = props => {
  const current = props.pageInfo.pageName
  const query = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "MM_LOGO.png" }) {
        childImageSharp {
          fixed(width: 500, height: 500) {
            base64
            width
            height
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <Navbar id={props.homeBool == "true" ? "headerHome" : ""} expand="lg">
      <Navbar.Brand>
        <div style={{ display: "flex" }}>
          <Link to="/">
            <Img
              style={{ marginTop: ".5em" }}
              fixed={query.logo.childImageSharp.fixed}
            />
          </Link>
          <h1>
            <Link to="/" style={{ textDecoration: "none" }}>
              Lettings
              <br />
              Cleaning
            </Link>
          </h1>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className={props.homeBool == "true" ? "navbar-nav-home" : ""}>
          <Nav.Link>
            <Link
              to="/"
              style={
                current == "index"
                  ? {
                      color: "#B5DBEF",
                    }
                  : {}
              }
            >
              Home
            </Link>
          </Nav.Link>
          {/*<Nav.Link>
            <Link
              to="/about"
              style={current == "about" ? { color: "#007AFF" } : {}}
            >
              About
            </Link>
          </Nav.Link>*/}
          <Nav.Link>
            <Link
              to="/services"
              style={current == "services" ? { color: "#007AFF" } : {}}
            >
              Services
            </Link>
          </Nav.Link>
          {/*
          <Nav.Link>
            <Link
              to="/clients"
              style={current == "clients" ? { color: "#007AFF" } : {}}
            >
              Clients
            </Link>
          </Nav.Link>*/}
          <Nav.Link>
            <Link
              to="/contact"
              style={current == "contact" ? { color: "#007AFF" } : {}}
            >
              Contact
            </Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
export default Header
