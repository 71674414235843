/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

import "../styles/bootstrap.min.css"

import Header from "./header"
import Navbar from "./navBar"

const navigationStyle = {
  listStyleType: "none",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
  paddingInlineStart: 0,
}

const Layout = ({ children, pageInfo, homeBool }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main">
          <Row noGutters className="justify-content-center">
            <Col>
              <Header homeBool={homeBool} pageInfo={pageInfo} />
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Container fluid className="mt-5">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid className="em-0">
          <hr />
          <Row>
            <Col sm={12} mx-auto>
              <ul style={navigationStyle}>
                <li style={{ paddingRight: "0.3em" }}>
                  <Link to="/"> Home </Link>
                </li>
                <li style={{ paddingRight: "0.3em" }}>
                  <Link to="/about"> About </Link>
                </li>
                <li style={{ paddingRight: "0.3em" }}>
                  <Link to="/services"> Services </Link>
                </li>
                <li style={{ paddingRight: "0.3em" }}>
                  <Link to="/clients"> Clients </Link>
                </li>
                <li style={{ paddingRight: "0.3em" }}>
                  <Link to="/contact"> Contact </Link>
                </li>
              </ul>
            </Col>
            <Col sm={12}>
              <footer>
                <span>
                  <p style={{ textAlign: "center" }}>
                    MM Lettings Cleaning UK Ltd is a private limited company
                    (Company No. 12660244) © {new Date().getFullYear()}
                  </p>
                </span>
              </footer>
            </Col>
          </Row>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Crewe, Cheshire",
                "postalCode": "CW2 8PT",
                "streetAddress": "42 Bramhall Rd, Crewe CW2 8PT"
              },
              "email": "mmlettingscleaningltd(at)gmail.com",
              "member": [
                {
                  "@type": "Organization"
                }
              ],
              "areaServed": {
                "@type": "GeoCircle",
                "geoMidpoint": {
                    "@type": "GeoCoordinates",
                    "latitude": 53.090456,
                    "longitude": -2.469396
                },
                "geoRadius": 2300
            },
              "name": "MM Lettings Cleaning UK LTD",
              "telephone": "01270749380"
            }
          `}
          </script>
        </Container>
      </>
    )}
  />
)

export default Layout
